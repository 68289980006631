import { LoginForm } from 'components/LoginForm';
import { MaxWidth } from 'components/MaxWidth';
import { Page } from 'components/Page';
import { RichText } from 'components/RichText';
import { SubText } from 'components/Subtext';
import Link from 'next/link';
import styles from './login.module.css';
import type { NextPage } from 'next';

const Login: NextPage = () => (
  <Page title="login" hideIfAuth>
    <MaxWidth size="small">
      <div className={styles.richTextContainer}>
        <RichText>
          <h1>Inloggen</h1>
        </RichText>
      </div>
      <LoginForm />
      <div className={styles.registerNowContainer}>
        <SubText>
          Heb je nog geen acount?{' '}
          <Link href="/welkom" legacyBehavior>
            <a>Registreer je nu!</a>
          </Link>
        </SubText>
      </div>
    </MaxWidth>
  </Page>
);

export default Login;
