import { PremiumButtonWithOverlay } from 'components/PremiumButtonWithOverlay';
import { ReactNode, isValidElement } from 'react';
import { RichText } from 'components/RichText';
import styles from './FormGroup.module.css';

interface Properties {
  children: ReactNode;
  description?: ReactNode;
  isPremium?: boolean;
  title?: string;
  id?: string;
}

export const FormGroup = ({ children, description, isPremium, title, id }: Properties) => {
  const descriptionNode = description && isValidElement(description) ? description : <p>{description}</p>;

  return (
    <div>
      {(!!title || !!description) && (
        <div className={styles.header}>
          <RichText>
            {!!title && <h2 id={id}>{title}</h2>}
            {!!descriptionNode && descriptionNode}
          </RichText>
          {isPremium && <PremiumButtonWithOverlay />}
        </div>
      )}
      <div className={styles.childrenContainer}>{children}</div>
    </div>
  );
};
