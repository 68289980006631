import { ApiError } from 'generated/api';
import { captureException } from 'utils/captureException';
import { Form, Input, PasswordInput } from 'components/Form';
import { FormGroup } from 'components/FormGroup';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useUser } from 'hooks/UserContext';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

const initialValues = { email: '', password: '' };

export const LoginForm = (): JSX.Element => {
  const [error, setError] = useState<string>();
  const { login } = useUser();
  const router = useRouter();

  const onSubmit = async ({ email, password }: { email: string; password: string }) => {
    try {
      setError(undefined);
      const user = await login({ email, password });
      await router.push(user?.admin ? '/admin' : '/');
    } catch (error_) {
      if (error_ instanceof ApiError && error_.status === 401) {
        setError('Het e-mailadres of wachtwoord is onjuist.');
      } else {
        setError('Er ging iets mis, probeer nogmaals in te loggen.');
        captureException(error_);
      }
    }
  };

  return (
    <Form
      buttonLabel="Inloggen"
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      error={error}
    >
      <FormGroup>
        <Input label="E-mailadres" name="email" type="email" isRequired placeholder="hiereen@mailadres.nl" />
        <PasswordInput
          label="Wachtwoord"
          name="password"
          isRequired
          placeholder="·······"
          textNextToLabel="Wachtwoord vergeten"
          textNextLink="/wachtwoord-vergeten"
        />
      </FormGroup>
    </Form>
  );
};
