import { FocusEventHandler, useState } from 'react';
import { Icon } from 'components/Icon';
import { RichText } from 'components/RichText';
import styles from './PremiumButtonWithOverlay.module.css';

export const PremiumButtonWithOverlay = () => {
  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <div className={styles.container}>
      <PremiumButton onFocus={() => setShowOverlay(true)} onBlur={() => setShowOverlay(false)} />
      {showOverlay && <PremiumOverlay />}
    </div>
  );
};

interface PremiumButtonProperties {
  onFocus: FocusEventHandler<HTMLButtonElement>;
  onBlur: FocusEventHandler<HTMLButtonElement>;
}

const PremiumButton = ({ onFocus, onBlur }: PremiumButtonProperties) => (
  <button className={styles.button} type="button" onFocus={onFocus} onBlur={onBlur}>
    <span className={styles.buttonInner}>
      <span>Premium</span>
      <Icon type="info_circle" />
    </span>
  </button>
);

const PremiumOverlay = () => (
  <div className={styles.overlay}>
    <RichText>
      <h4>Ga Premium ⭐</h4>
      <p>Met een Premium lidmaatschap geniet je van de volgende bedrijfsvoordelen:</p>
      <ul>
        <li>Nulla accumsan sed imperdiet aenean turpis consectetur tellus lectus orci</li>
        <li>Elementum scelerisque sit pellentesque iaculis sed ornare</li>
        <li>Sed lobortis tortor, ornare nunc, a</li>
        <li>Integer tristique quis nunc justo.</li>
      </ul>
    </RichText>
  </div>
);
