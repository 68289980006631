import { Button } from 'components/Button';
import {
  ErrorMessage,
  Field,
  FieldAttributes,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikValues,
  useField,
} from 'formik';
import { Icon, IconType } from 'components/Icon';
import { FocusEvent, FormEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './Form.module.css';
import Link from 'next/link';

interface FormProperties<T> {
  buttonLabel: string;
  children: ReactNode;
  disabled?: boolean;
  enableReinitialize?: boolean;
  error?: string;
  formErrors?: FormikErrors<T>;
  initialValues: T;
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void;
  onValuesChange?: (values: T) => void;
  validationSchema?: unknown;
}

const noop = () => {
  // noop
};

export const Form = <T extends FormikValues>({
  buttonLabel,
  children,
  disabled,
  enableReinitialize,
  error,
  formErrors,
  initialValues,
  onSubmit,
  onValuesChange,
  validationSchema,
}: FormProperties<T>): JSX.Element => (
  <Formik
    enableReinitialize={enableReinitialize}
    initialValues={initialValues}
    onSubmit={disabled ? noop : onSubmit}
    validateOnChange={false}
    validationSchema={validationSchema}
  >
    {({ errors, isSubmitting, handleSubmit, setErrors, submitCount, values }) => (
      <FormInner
        buttonLabel={buttonLabel}
        disabled={disabled}
        errors={formErrors}
        setErrors={setErrors}
        error={
          Object.keys(errors).length > 0 && submitCount > 0
            ? 'Niet alle velden zijn juist ingevoerd, controleer je input.'
            : error
        }
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        onValuesChange={onValuesChange}
        values={values}
      >
        {children}
      </FormInner>
    )}
  </Formik>
);

interface FormInnerProperties<T> {
  buttonLabel: string;
  children: ReactNode;
  disabled?: boolean;
  error?: string;
  errors?: FormikErrors<T>;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  isSubmitting: boolean;
  onValuesChange?: (values: T) => void;
  setErrors?: (value: FormikErrors<T>) => void;
  values: T;
}

const FormInner = <T extends FormikValues>({
  buttonLabel,
  children,
  disabled,
  error,
  errors,
  setErrors,
  handleSubmit,
  isSubmitting,
  onValuesChange,
  values,
}: FormInnerProperties<T>) => {
  useEffect(() => {
    if (onValuesChange) {
      onValuesChange(values);
    }
  }, [onValuesChange, values]);

  useEffect(() => {
    if (setErrors && !!errors) {
      setErrors(errors);
    }
  }, [errors, setErrors]);

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.formFieldsContainer}>{children}</div>
      <div className={styles.buttonContainer}>
        <Button disabled={disabled} loading={isSubmitting} type="submit">
          {buttonLabel}
        </Button>
      </div>
      {error && <div className={styles.formError}>{error}</div>}
    </form>
  );
};
export interface InputProperties {
  addHttpsOnBlur?: boolean;
  children?: ReactNode;
  description?: string;
  icon?: IconType;
  isRequired?: boolean;
  label?: string;
  maxLength?: number;
  name: string;
  onIconClick?: () => void;
  textNextLink?: string;
  textNextToLabel?: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
}

const httpsRegex = /^https?:\/\//;

export const Input = ({
  addHttpsOnBlur,
  children,
  description,
  icon,
  isRequired,
  label,
  maxLength,
  name,
  onIconClick,
  textNextLink,
  textNextToLabel,
  type = 'text',
  ...rest
}: FieldAttributes<InputProperties>): JSX.Element => {
  const [{ onBlur: handleBlur }, { error, touched }, { setValue }] = useField(name);
  const classNames = [styles.input, error && touched ? styles.hasError : undefined].filter(Boolean).join(' ');

  const onBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (event.target.value.length > 0 && !httpsRegex.test(event.target.value)) {
        setValue(`https://${event.target.value}`);
      } else {
        handleBlur(event);
      }
    },
    [handleBlur, setValue]
  );

  return (
    <InputContainer
      description={description}
      icon={icon}
      isRequired={isRequired}
      label={label}
      maxLength={maxLength}
      name={name}
      onIconClick={onIconClick}
      textNextToLabel={textNextToLabel}
      textNextLink={textNextLink}
    >
      <Field
        className={classNames}
        id={name}
        maxLength={maxLength}
        name={name}
        required={isRequired}
        type={type}
        {...rest}
        onBlur={addHttpsOnBlur ? onBlur : undefined}
      >
        {children}
      </Field>
    </InputContainer>
  );
};

export interface LabelValueObject {
  label?: string;
  value?: string | number;
}

interface SelectBoxProperties extends InputProperties {
  options: LabelValueObject[];
}

export const SelectBox = ({ options, ...rest }: SelectBoxProperties): JSX.Element => {
  const optionElements = useMemo(
    () =>
      options.map(({ value, label }: LabelValueObject) => (
        <option key={value} value={value}>
          {label}
        </option>
      )),
    [options]
  );

  return (
    <Input {...rest} as="select" icon="chevronDown">
      {optionElements}
    </Input>
  );
};

export const TextArea = (properties: InputProperties): JSX.Element => <Input {...properties} as="textarea" rows={5} />;

export const PasswordInput = (properties: InputProperties): JSX.Element => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <Input
      {...properties}
      icon={showPassword ? 'eye_slash' : 'eye'}
      onIconClick={() => setShowPassword(!showPassword)}
      type={showPassword ? 'text' : 'password'}
    />
  );
};

interface RadioButtonProperties {
  label?: string;
  name: string;
  value?: string;
  description?: string;
}

const RadioButton = ({ name, value, label, description, ...rest }: RadioButtonProperties): JSX.Element => (
  <InputContainer name={name} {...rest}>
    <div className={styles.optionContainer}>
      <div className={styles.optionContainer}>
        <Field
          className={`${styles.radioButtonInput} ${styles.optionInput}`}
          id={value}
          name={name}
          type="radio"
          value={value}
        />
        <Icon type="check" />
      </div>
      {!!label && (
        <label className={styles.optionLabel} htmlFor={value}>
          {label}
        </label>
      )}
    </div>
    {!!(label && description) && <span className={styles.optionLabelDescription}>{description}</span>}
  </InputContainer>
);

export const RadioButtonGroup = ({
  name,
  options,
}: {
  name: string;
  options: Omit<RadioButtonProperties, 'name'>[];
}): JSX.Element => {
  const radioButtonElements = options.map(({ description, label, value }: Omit<RadioButtonProperties, 'name'>) => (
    <li key={value}>
      <RadioButton name={name} value={value} label={label} description={description} />
    </li>
  ));

  return (
    <div role="radiogroup" className={styles.inputContainer}>
      <ul className={styles.optionList}>{radioButtonElements}</ul>
    </div>
  );
};

interface CheckboxProperties {
  isRequired?: boolean;
  label?: string | ReactNode;
  name: string;
  value?: string;
}

export const Checkbox = ({ name, value, label, isRequired, ...rest }: CheckboxProperties): JSX.Element => (
  <InputContainer name={name} {...rest}>
    <div className={styles.optionContainer}>
      <div className={styles.optionContainer}>
        <Field
          required={isRequired}
          className={`${styles.checkboxInput} ${styles.optionInput}`}
          id={name}
          name={name}
          type="checkbox"
          value={value}
        />
        <Icon type="check" />
      </div>
      {!!label && (
        <label className={styles.optionLabel} htmlFor={name}>
          {label}
        </label>
      )}
    </div>
  </InputContainer>
);

interface InputContainerProperties {
  children: ReactNode;
  description?: string;
  icon?: IconType;
  isRequired?: boolean;
  label?: string;
  maxLength?: number;
  name: string;
  onIconClick?: () => void;
  textNextToLabel?: string;
  textNextLink?: string;
}

export const InputContainer = ({
  children,
  description,
  textNextToLabel,
  textNextLink,
  icon,
  isRequired,
  label,
  maxLength,
  name,
  onIconClick,
}: InputContainerProperties): JSX.Element => {
  const [{ value }] = useField(name);
  const iconClassNames = onIconClick
    ? `${styles.inputContainerChildrenIcon} ${styles.isClickable}`
    : styles.inputContainerChildrenIcon;

  return (
    <div className={styles.inputContainer}>
      {(!!label || isRequired) && (
        <div className={styles.inputContainerHeader}>
          <div className={styles.inputContainerTitle}>
            {!!label && <label htmlFor={name}>{label}</label>}
            <div>
              {textNextToLabel && (
                <div className={styles.textNextToLabel}>
                  {textNextLink ? <Link href={textNextLink}>{textNextToLabel}</Link> : textNextToLabel}
                </div>
              )}
              {isRequired && <div className={styles.requiredSymbol}>*</div>}
            </div>
          </div>
          {!!description && <div className={styles.inputContainerDescription}>{description}</div>}
        </div>
      )}
      <div className={styles.inputContainerChildrenContainer}>
        <div>{children}</div>
        {!!icon && (
          <span className={iconClassNames} onClick={onIconClick}>
            <Icon type={icon} />
          </span>
        )}
      </div>
      <div className={styles.inputContainerFooter}>
        <div>
          <ErrorMessage className={styles.inputContainerError} name={name} component="div" />
        </div>
        {!!maxLength && !!value && (
          <div className={styles.inputContainerMaxLength}>
            {value.length}/{maxLength}
          </div>
        )}
      </div>
    </div>
  );
};
